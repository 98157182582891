.projects {
  padding: 80px 50px 50px;
  background-color: #fcfcfc;

  color: #fc1b97;
}
.row.row-cols-1.row-cols-sm-2 {
  margin: 0px;
}
.projects a {
  color: #fc1b97;
  text-decoration: none;
}
.projects h3 {
  text-align: center;
  /* color: #fc46aa; */

  margin-bottom: 70px;
  line-height: 1.5;
  /* letter-spacing: 2px; */
}
.projects h4 {
  /* font-size: 25px;
  /* color: #fc46aa; */
  /* font-weight: 400; */
  font-weight: 300;
  font-size: 30px;
}
.projects h5 {
  font-weight: 230;
  font-size: 20px;
}

.projects p {
  color: #36022b;
  font-size: 16px;
  font-weight: 400;
}

.description {
  padding-top: 10px;
  
}
.projects img {
  border-radius: 15px;
  max-width: 65%;
}
.project-heading{
  display: flex;
  justify-content:space-between;
}
.contactButton {
  padding: 1px;
  
  max-height: 36px;
  max-width: 40px;
  border-color: #fc1b97;
  --bs-btn-bg: #fc1b87;
    --bs-btn-border-color: #fc1b87;
  -bs-btn-active-border-color: #fcae1e;
   --bs-btn-hover-bg: #fcae1e;
  --bs-btn-hover-border-color: #fcae1e;
  display: inline;
}
.single-article-card-large {
  background-color: #fcfcfc;
  border-style: none;
  margin-bottom: 70px !important;
}

@media (max-width: 827px) {
  .projects {
    padding: 40px 0px 0px;
  }
  .projects h3 {
    margin-bottom: 50px;
  }
}
@media (max-width: 577px) {
  .description {
    padding: 30px 30px 0px 50px;
  }
}

@media (max-width: 430px) {
  .projects {
    padding: 20px 0px 0px;
  }
  .projects h3 {
    margin-bottom: 30px;
  }
  .description {
    padding: 30px 30px 0px 30px;
  }
  .projects img {
    border-radius: 15px;
    max-width: 70%;
  }
}
