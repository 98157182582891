header {
  color: aliceblue;
  border-radius: 15px;
  max-width: 100%;
  margin: 0;
  margin-top: 60px;
}
header .row {
  margin: 0px;
}
.header img {
  max-width: 70%;
  max-height: 80%;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
  /* margin-top: 30px; */
}
.header h1 {
  font-size: 90px !important;
  font-weight: 400;
  line-height: 1.5;
}
.header h2 {
  color: #fcfcfc;
  font-size: 40px !important;
  font-weight: 250;
}
.header h3 {
  font-weight: 200;
  font-size: 30px !important;
}
.heading {
  background-color: #fc1b97;
  color: #fcfcfc;
  padding: 170px 0px 0px 50px;
}
.pic {
  background-color: #f3a732;
  padding: 130px 0px 0px 0px;
  max-width: 100%;
}

@media (max-width: 1336px) {
  .header h1 {
    font-size: 80px !important;
  }
}
@media (max-width: 1221px) {
  .header h1 {
    font-size: 75px !important;
  }
}
@media (max-width: 1132px) {
  .heading {
    padding: 140px 0px 0px 30px;
  }
  .pic {
    padding: 120px 0px 0px 0px;
  }
  .header h1 {
    font-size: 70px !important;
  }
}
@media (max-width: 1024px) {
  .heading {
    padding: 140px 0px 0px 30px;
  }
  .pic {
    padding: 120px 0px 0px 0px;
  }
  .header h1 {
    font-size: 60px !important;
  }
  .header h2 {
    color: #fcfcfc;
    font-size: 30px !important;
    font-weight: 250;
  }
  .header h3 {
    font-weight: 200;
    font-size: 20px !important;
  }
}
@media (max-width: 879px) {
  .header h1 {
    font-size: 55px !important;
  }
  .heading {
    padding: 100px 0px 30px 30px;
  }

  .pic {
    padding: 80px 0px 30px 0px;
  }
  /* .header h2 {
  color: #fcfcfc;
  font-size: 30px !important;
  font-weight: 250;
}
.header h3 {
  font-weight: 200;
  font-size: 20px !important;
}} */
}
@media (max-width: 827px) {
  .heading {
    padding: 100px 0px 30px 30px;
  }
  .header h1 {
    font-size: 50px !important;
  }
  .pic {
    padding: 80px 0px 30px 0px;
  }
}
@media (max-width: 767px) {
  .heading {
    padding: 50px 70px 20px 100px;
  }
  .pic {
    padding: 20px 0px 20px 0px;
  }
  .header h1 {
    font-size: 60px !important;
    font-weight: 400;
    line-height: 1.5;
  }
  .header img {
    max-width: 200px;
    max-height: 200px;
  }
  .header h2 {
    color: #fcfcfc;
    font-size: 30px !important;
    font-weight: 250;
  }
  .header h3 {
    font-weight: 200;
    font-size: 18px !important;
  }
  header {
    margin-top: 50px;
  }
}
@media (max-width: 655px) {
  header {
    margin-top: 41px;
  }
}
@media (max-width: 583px) {
  .header h1 {
    font-size: 50px !important;
    font-weight: 400;
    line-height: 1.5;
  }
}
@media (max-width: 514px) {
  .heading {
    padding: 50px 0px 0px 50px;
  }
   header {
    margin-top: 50px;
  }
}
@media (max-width: 450px) {
  .heading {
    padding: 50px 0px 0px 20px;
  }
  .pic {
    padding: 20px 0px 20px 0px;
  }
  .header img {
    max-width: 200px;
    max-height: 200px;
  }
   header {
    margin-top: 50px;
  }
}
@media (max-width: 364px) {
  .heading {
    padding: 50px 0px 20px 20px;
  }
  .pic {
    padding: 20px 0px 20px 0px;
  }
  .header h1 {
    font-size: 42px !important;
    font-weight: 400;
    line-height: 1.5;
  }
  .header img {
    max-width: 200px;
    max-height: 200px;
  }
  .header h2 {
    color: #fcfcfc;
    font-size: 25px !important;
    font-weight: 250;
  }
  .header h3 {
    font-weight: 200;
    font-size: 16px !important;
  }
   header {
    margin-top: 50px;
  }
}
