.footer {
  background-color: rgba(0, 0, 0, 0.15);
  padding:20px 20px 50px 20px;
}
.boxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  color: black;
  font-weight:200;
  font-size: 13px;
}

ul {
  list-style-type: none;
}
.footer p {
  font-weight: 200;
  font-size: 13px;
}
.footer a {
  color: black;
}
.footer a:hover {
  color:aliceblue;
  font-weight: bold;
}
@media (max-width: 1176px) {
 
 .boxes{
  font-size: 12px !important;
 }
 .footer p{
  font-size: 12px !important;
 }
}

@media (max-width: 654px) {
 .boxes{
  font-size: 10px !important;
 }
 .footer p{
  font-size: 10px !important;
 }
}
@media (max-width: 380px) {
.footer {
  background-color: rgba(0, 0, 0, 0.15);
  padding:20px 10px 20px 10px;
}
ul.footerlist{
  padding-left:10px;
}
}