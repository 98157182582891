body {
  background-color: #fcfcfc;
  overflow-x: hidden !important;
  
}
h2 {
  font-size: 40px;
  font-weight: 250;
  color: #ff007f;
}
section {
  background-color: #f3a732;
}
@media (max-width: 1334px) {
  h1 {
    font-size: 60px !important;
  }
}
@media (max-width: 1176px) {
  h1 {
    font-size: 50px !important;
  }
 
}
@media (max-width: 1024px) {
  h1 {
    font-size: 40px !important;
  }
  h2 {
    font-size: 25px !important;
  }
  h3 {
    font-size: 25px !important;
  }
  h4 {
    font-size: 20px !important;
  }
  h5 {
    font-size: 18px !important;
  }
  p {
    font-size: 14px !important;
  }
}
@media (max-width: 827px) {
  h1 {
    font-size: 35px !important;
  }
 
}
@media (max-width: 430px) {

  h1 {
    font-size: 20px !important;
  }
  h2 {
    font-size: 18px!important;
  }
  h3 {
    font-size: 15px ;
  }
}