.container-about {
  max-width: 60%;
  display: block;
  margin: 0 auto;
  padding: 10px 0px;
}
.about {
  text-align: left;
  margin: 100px;
  color: #36022b;
}
h3 {
  font-size: 40px;
  font-weight: 350;
  margin-bottom: 30px;
  /* letter-spacing: 3px */
}
p {
  font-weight: 300;
  line-height: 2;
  font-size: 18px;
}
.link {
  color: #fc1b97;
  text-decoration: none;
  font-weight: bold;
}
.link:hover {
  color: black;
  font-weight: bold;
}
@media (max-width: 1176px) {
  .about {
    margin: 20px 0px;
  }
}
@media (max-width: 430px) {
  .container-about {
    max-width: 80%;
  }

}
@media (max-width: 380px) {
 .container-about {
    max-width: 88%;
  }
}
